import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "И-мэйлээр мэдээлэл, сонордуулга авах",
  "description": null,
  "author": "OTC help",
  "category": "account-management",
  "date": "2022-03-28T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 7
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`И-мэйлээр мэдээлэл, сонордуулга авах`}</h2>
    <ol>
      <li parentName="ol">{`Хэрэглэгч бүртгэлтэй и-мэйл хаягтаа платформ дахь шинэ мэдээ мэдээллийн сонордуулгыг хүлээн авахыг хүсвэл дэлгэцийн баруун дээд булан дахь өөрийн нэрийг дарж "хэрэглэгчийн тохиргоо" хэсэгт очно.`}</li>
      <li parentName="ol"><strong parentName="li">{`[Email Notification Settings]`}</strong>{` хэсгийг сонгон и-мэйл хаягаар сонордуулга авах мэдээллээ дараах жагсаалтаас сонгож идэвхижүүлнэ:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`[Events]`}{` буюу голлох үйл явдлын мэдээлэл`}</li>
      <li parentName="ul">{`[Disclosures]`}{` буюу үнэт цаасны явцын мэдээлэл`}</li>
      <li parentName="ul">{`[News]`}{` буюу ББЗЗ-ийн мэдээ мэдээлэл`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      